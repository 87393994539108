import React, { Component } from 'react';

import classes from './Testimony.module.scss';

class Testimony extends Component {
    render() { 

        return (
            <div className={classes.testimony}> 
                { this.props.id === 5 
                    ?  <h3 className={classes.testimonyHeading + ' mb-10 orangered'}>{this.props.title}</h3>
                    :  <h3 className={classes.testimonyHeading + ' mb-10'}>{this.props.title}</h3>
                }
                <p className={classes.date}>{this.props.date}</p>
                <p className={classes.perex}>{this.props.perex}</p>
                <div className={classes.button}>
                    <button className={["btnOutline", "btn", classes.btnOutlineSteelBlue].join(" ")}>Čítaj viac</button>
                </div>
            </div>
        );
    }

}

export default Testimony;