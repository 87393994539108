import React, { Component } from 'react';

import ReactAudioPlayer  from 'react-audio-player';

import chapter01 from '../../assets/audio/book/01-detsky_travnicky_sen.mp3';
import chapter02 from '../../assets/audio/book/02-vrabelska_euforia.mp3';
import chapter03 from '../../assets/audio/book/03-trnavske_silenzio.mp3';
import chapter04 from '../../assets/audio/book/04-zelena_juliska.mp3';
import chapter05 from '../../assets/audio/book/05-belasa_nostalgia.mp3';
import chapter06 from '../../assets/audio/book/06-obratenie_saula.mp3';
import chapter07 from '../../assets/audio/book/07-spoved.mp3';
import chapter08 from '../../assets/audio/book/08-futbalovy_hilfsarbeiter.mp3';
import chapter09 from '../../assets/audio/book/09-ave_maria.mp3';
import chapter10 from '../../assets/audio/book/10-mystika.mp3';
import chapter11 from '../../assets/audio/book/11-manzelstvo.mp3';
import chapter12 from '../../assets/audio/book/12-knazstvo.mp3';
import chapter13 from '../../assets/audio/book/13-posolstvo.mp3';


class BookAudio extends Component {
    state = {
        activeChapter: 0,
        chapterSet: [
            { id: 1, name: '01 Detský trávnický sen', src: chapter01 }, 
            { id: 2, name: '02 Vrábeľská eufória', src: chapter02 }, 
            { id: 3, name: '03 Trnavské silenzio', src: chapter03 }, 
            { id: 4, name: '04 Zelená Juliska', src: chapter04 }, 
            { id: 5, name: '05 Belasá nostalgia', src: chapter05 }, 
            { id: 6, name: '06 Obrátenie Šaula', src: chapter06 }, 
            { id: 7, name: '07 Spoveď', src: chapter07 }, 
            { id: 8, name: '08 Futbalový Hilfsarbeiter', src: chapter08 }, 
            { id: 9, name: '09 Ave Mária', src: chapter09 }, 
            { id: 10, name: '10 Mystika', src: chapter10 }, 
            { id: 11, name: '11 Manželstvo', src: chapter11 }, 
            { id: 12, name: '12 Kňazstvo', src: chapter12 }, 
            { id: 13, name: '13 POSOLSTVO', src: chapter13 }
        ],
        autoPlay: false
    }
    
    newChapter(index) {
        this.setState({activeChapter: index, autoPlay: true});
    }
    
    render() {
        const { activeChapter, chapterSet, autoPlay } = this.state;

        const audioList = chapterSet.map((chapter, index) => {
            return (
                <li key={chapter.id} onClick={() => this.newChapter(index)}>
                    {chapter.name}
                </li>
            );
        });


        return (
            <div>
                <p className="current">Práve sa prehráva: <span className="activeAudio">{this.state.chapterSet[this.state.activeChapter].name}</span></p>
                <ReactAudioPlayer
                    controls={true}
                    autoPlay={autoPlay}
                    src={chapterSet[activeChapter].src}
                    className="audioPlayer"
                />
                <ul className="audio-component">
                    {audioList}
                </ul>
            </div>
        );
    };
}

export default BookAudio;