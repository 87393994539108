import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NotFound.module.scss';

class NotFound extends Component {

    goBack = () => {
        this.props.history.goBack();
    }
    
    render() {
        
        return (
            <div className="container top">
                <h3>Ľutujeme, stránka sa nenašla.</h3>
                <div className={classes.row + " row"}>
                    <button className={classes.marginRight + " btn btnOutline"}><NavLink to="/" exact>Domov</NavLink></button>
                    <button className="btn btnOutline" onClick={this.goBack}>Späť</button>
                </div>
            </div>
        );
    }
}

export default NotFound;