import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import flag_sk from '../../assets/images/flag_sk.jpg';
import flag_ru from '../../assets/images/flag_ru.jpg';
import flag_de from '../../assets/images/flag_de.jpg';
import signature from '../../assets/images/signature.jpg';
import bookImg from '../../assets/images/book_lighter_shadow.jpg';
import bookDownloadSk from '../../assets/files/UJKN_Fijalka.pdf';
import bookDownloadDe from '../../assets/files/UJKN_Fijalka-de.pdf';

import classes from '../Book/Book.module.scss';
import classesDe from './De.module.scss';

const De = () => {
    return (
        <div>
            <section className={classesDe.section}>
                <div className="container-smaller top">
                    <div className="actualities bold">
                        NACHRICHTEN: Den neuen Artikel <NavLink to="/de/aufruf-auf-das-slowakische-volk" className="orangered">Aufruf an das slowakische Volk</NavLink> können Sie <NavLink to="/de/aufruf-auf-das-slowakische-volk" className="orangered underlined">hier</NavLink> lesen. 
                        <br></br>
                        Zeugnis von Katarína Križanovičová zum Lesen <NavLink to="/de/zeugnis-krizanovicova" className="orangered underlined">hier</NavLink>.
                    </div>
                    <div className={["row", classes.flexAlign].join(" ")}>
                        <div className="col1">
                            <img src={bookImg} alt="" className={classes.bookSmall}/>
                        </div>
                        <div className={classes.col2}>
                            <h2 className="violet">Jünger Jesus Christus Nazareth</h2>
                            <h4 className="violet">Peter Fijalka</h4>
                            <div className={classes.handwritten}>
                                Diese Autobiographie widme ich allen, die sich in iregendeiner Art und Weise dazu beteiligt haben, einen besseren Mensch aus mir zu machen.
                            </div>
                            <div className="row flex-end">
                                <img src={signature} alt="" className={classes.signature}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classesDe.section}>
                <div className={classes.bookIntro}>
                    <div className="container">
                        <div className={classes.textContainer}>
                            <img className={classes.logoBig} src={logo} alt="logo"/>    
                            <div className={classes.pWrapper}>
                                <p>Der <span className="bold">Kreis</span> ist ein Symbol für Ewigkeit und Unendlichkeit.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p>Das <span className="bold">Dreieck</span> ist das Symbol der göttlichen Dreifaltigkeit.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p>Das <span className="bold">Doppelkreuz</span> ist das Symbol für die Gesetze des Universums, 
                                    <br/>das Symbol der Brüderschaft,
                                    <br/>das Symbol der Vereinigung des Westerns mit dem Osten
                                    <br/>und das Symbol der Vereinigung Christi Leiden mit den unseren. 
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p>Das <span className="bold">U</span> ist eine Initiale für den Jünger (Učeník). Es hat die Form eines Gefäßes oder eines Reagenzglases. 
                                    <br/>Ergänzen erforschen, die Wahrheit suchen. Das U ist gleichzeitig das Gefäß des Heiligen Geistes
                                    <br/>und das Symbol des Universums.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p>Das <span className="bold">J</span> ist die Initiale für des Namen Jesus (Ježiš) und bedeutet Emanuel, Gott mit uns, Erlöser. 
                                    <br/>Der Buchstabe J hat die Form eines Ankers, was Hoffnung und Rettung bedeutet.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">K</span> bedeutet Christus (Kristus) aus dem griechischen Christos, 
                                    <br/>was von Gott Vater gesalbten erstgeborenen Sohn, König der Geister, bedeutet.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">N</span> ist Nazareth (Nazaretský), das Siegel der Echtheit.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p>Der <span className="bold">Kelch mit Hostie</span> ist das Zeichnen für Eucharistie.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p>Der <span className="bold">Fisch</span> ist das Symbol der ersten Christen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classesDe.section}>
                <div className="container">
                    <div className={["row", classesDe.row].join(" ")}>
                        <div className="w-50">
                            <div className="section">
                                <h3 className={classes.h3}>Das Buch für online lesen:</h3>
                                <div className="row column special-row">
                                    <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/ujkn/docs/ujkn_de" target="_blank" rel="noopener noreferrer">Lies online auf Deutch<img className={classes.flag} src={flag_de} alt="de"/></a>
                                    <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/ujkn/docs/ujkn_fijalka" target="_blank" rel="noopener noreferrer">Lies online auf Slowakisch<img className={classes.flag} src={flag_sk} alt="sk"/></a>
                                    <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/pm_work/docs/ujkn_ru" target="_blank" rel="noopener noreferrer">Lies online auf Russisch<img className={classes.flag} src={flag_ru} alt="ru"/></a>
                                </div>
                            </div>
                        </div>

                        <div className="w-50">
                            <div className="section">
                                <h3 className={classes.h3}>Das Buch zum Herunterladung (pdf):</h3>
                                <div className="row wrap">
                                    <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={bookDownloadDe} download>
                                    Lade das gesamte Buch auf Deutsch herunter (25 MB)&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                    </a>
                                    <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={bookDownloadSk} download>
                                    Lade das gesamte Buch auf Slowakisch herunter (25 MB)&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classesDe.section}>
                <div className="container">
                    <h3 className={classesDe.h3}>Kontakt</h3>
                    <p>Bratislava, Slowakische Republik</p>

                    <a href="mailto:fialky@centrum.sk" className={classesDe.mailto}>fialky@centrum.sk</a>

                    <h4>Bankverbindung:</h4>
                    <p>SK23 8330 0000 0021 0166 3662</p>
                </div>
            </section>
        </div>
    );
}

export default De;