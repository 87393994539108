import React from 'react';

import classes from './Oz.module.scss';
import vyhlasenie2percenta from '../../assets/vyhlasenie_ujkn_2_percenta_2022.pdf';

const oz = () => {
    return (
        <div className="container top">
            <div className={classes.bg}></div>
            <h1 className={["h1-main", classes.violet].join(" ")}>Občianske združenie <span className={classes.orange}>UJKN</span></h1>

            <section className={classes.section}>
                <h3 className={classes.h3}>Ciele a činnosť</h3>
                <ul className={classes.ul}>
                    <li>poslaním združenia je, odvolávajúc sa na Cyrilo-Metodské dedičstvo, duchovné zjednotenie Slovenska a iných národov</li>
                    <li>hľadaním Pravdy na základe Písma Svätého utvárať a formovať slobodných a uzdravených ľudí (UJKN)</li>
                    <li>založenie vzdelávacieho a liečebného centra, ktoré bude poskytovať poradenstvo, konzultácie, vzdelávaciu činnosť, kresťanské meditácie a modlitby</li>
                    <li>vzdelávacia a školiaca činnosť</li>
                    <li>tvorba, vyhotovenie a distribúcia duchovnej literatúry (knihy, časopisy, brožúry)</li>
                    <li>prekladateľská činnosť</li>
                    <li>vytváranie elektronických nosičov s duchovnou tematikou (hudba, duchovné slovo, rozprávky), vytváranie videofilmov</li>
                    <li>informovanie o svojej činnosti prostredníctvom internetu</li>
                    <li>organizovanie besied, rozhovorov, seminárov a stretnutí</li>
                    <li>zostavovanie a distribúcia vtipných, náučných a liečivých hier na zlepšenie poznávacích procesov rôznych cieľových skupín</li>
                    <li>gastronómia, recepty a rady pre zdravý životný štýl</li>
                    <li>turisticko-športová činnosť "don boskovskou" metódou</li>
                    <li>ochrana prírody a využívanie jej darov pre človeka</li>
                    <li>charitatívna pomoc</li>
                </ul>
            </section>
            <section className={classes.section}>
                <h3 className={classes.h3}>Vyhlásenie na darovanie 2&nbsp;% z&nbsp; dane</h3>
                <p>Tlačivo na darovanie 2&nbsp;% z&nbsp;dane si môžete stiahnuť tu: </p>
                <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={vyhlasenie2percenta} download>Vyhlásenie UJKN&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i></a>
            </section>
        </div>
    );
}

export default oz;