import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import src_flag_sk from '../../assets/images/flag_sk.jpg';
import src_flag_de from '../../assets/images/flag_de.jpg';
import src_flag_ru from '../../assets/images/flag_ru.jpg';

import classes from './Header.module.scss';

class Header extends Component {
    state = {
        mobileMenuOpen: false,
        scrollTop: 0,
        whiteMenuBcg: false,
        lang: this.lang,
        langMenuOpen: false,
        faith: true
    };

    headerClasses = [classes.header];
    headerClassesMobile = [];
    
    langAll = ['sk', 'de', 'ru'];
    langNotActive = this.langAll.filter((lang) => lang !== this.state.lang);
    flagsNotActive = this.langNotActive.map(lang => 'flag_' + lang + '.png');
    
    langMenuOpen = [classes.toggle];
    langMenuClosed = [classes.toggle, "displayNone"];    

    url;
    lang;
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(window.location.pathname.slice(1,3) === 'de') {
            this.setState({lang: 'de'});
        }
        else if(window.location.pathname.slice(1,3) === 'ru') {
            this.setState({lang: 'ru'});
        }
        else {
            this.setState({lang: 'sk'});
        }
    }
    
    handleScroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        this.setState({scrollTop: scrollTop});
        
        if(this.state.scrollTop > 50) {
            this.headerClasses = [classes.header, classes.headerFilled].join(' ');
        } else {
            this.headerClasses = [classes.header];
        }
    }
    
    handleScroll0 = () => {
        document.documentElement.scrollTop = 0
        this.setState({scrollTop: 0});
        this.headerClasses = [classes.header];
    }
    
    // pri 918px zacina mobilne menu
    toggleMenu = () => {
        if(window.innerWidth < 918) {                                               
            this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen, 
                whiteMenuBcg: !this.state.whiteMenuBcg });
        }
    }

    toggleLanguageHandler = () => {
        this.setState( {langMenuOpen: !this.state.langMenuOpen} );
    } 

    handleActiveLang = (lang) => {
        this.setState( {lang: lang} );
    } 

    closeFaith = () => {
        this.setState({faith: false});
    }
        
    whiteMenu = this.state.whiteMenuBcg ? classes.whiteMenuBcg : "";
    
    render() {
        this.url = window.location.pathname;
        this.lang = this.url.slice(1,3);

        let actual_sk = <img src={src_flag_sk} alt="sk" className={classes.flag}/>;
        let actual_ru = <img src={src_flag_ru} alt="ru" className={classes.flag}/>;
        let actual_de = <img src={src_flag_de} alt="de" className={classes.flag}/>;

        let flag_sk = <NavLink to="/" exact key="sk" onClick={() => {this.handleScroll0(); this.handleActiveLang('sk');}}><img src={src_flag_sk} alt="sk" className={classes.flag}/></NavLink>;


        let flag_de = <NavLink to="/de" exact key="de" onClick={() => {this.handleScroll0(); this.handleActiveLang('de');}}><img src={src_flag_de} alt="de" className={classes.flag}/></NavLink>;

        let flag_ru = <NavLink to="/ru" exact key="ru" onClick={() => {this.handleScroll0(); this.handleActiveLang('ru');}}><img src={src_flag_ru} alt="ru" className={classes.flag}/></NavLink>;

        let actualMenuFlag;
        let hiddenMenuFlags;
        
        if(this.state.lang === 'ru') {
            actualMenuFlag = actual_ru;
            hiddenMenuFlags = [flag_ru, flag_sk, flag_de];
        }
        else if (this.state.lang === 'de') {
            actualMenuFlag = actual_de;
            hiddenMenuFlags = [flag_de, flag_sk, flag_ru];
        }
        else {
            actualMenuFlag = actual_sk;
            hiddenMenuFlags = [flag_sk, flag_de, flag_ru];
        }


        return (
            <header className={[this.headerClasses, this.state.whiteMenuBcg ? classes.whiteMenuBcg : ""].join(' ')}>
                <div className="container">
                    <div className={classes.navbar}>
                        <div>
                            <NavLink to="/" exact className={classes.logo} onClick={() => this.handleScroll0()}>
                                <img src={logo} alt="logo ujkn"/>
                                <h2>UJKN</h2>
                            </NavLink>
                        </div>
                        <nav className={classes.nav}>
                            <ul className={[classes.mainMenu, (this.state.mobileMenuOpen ? classes.show : "")].join(' ')}>
                                <li><NavLink to="/kniha" onClick={() => {this.handleScroll0(); this.handleActiveLang('sk'); this.toggleMenu()}}>Kniha</NavLink></li>
                                <li><NavLink to="/rozpravky" onClick={() => {this.handleScroll0();  this.handleActiveLang('sk'); this.toggleMenu()}}>Rozprávky</NavLink></li>
                                <li><NavLink to="/hra" onClick={() => {this.handleScroll0();  this.handleActiveLang('sk'); this.toggleMenu()}}>Hra</NavLink></li>
                                <li><NavLink to="/svedectva" onClick={() => {this.handleScroll0();  this.handleActiveLang('sk'); this.toggleMenu()}}>Svedectvá</NavLink></li>
                                <li><NavLink to="/oz" onClick={() => {this.handleScroll0();  this.handleActiveLang('sk'); this.toggleMenu()}}>Občianske združenie</NavLink></li>
                                <li><NavLink to="/kontakt" onClick={() => {this.handleScroll0();  this.handleActiveLang('sk'); this.toggleMenu()}}>Kontakt</NavLink></li>
                                <li onClick={() => this.toggleLanguageHandler()}>
                                    <div className={["row", classes.dropdownMenu].join(" ")}>
                                        {actualMenuFlag}
                                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                                    </div>
                                    <div className={this.state.langMenuOpen ? this.langMenuOpen.join(" ") : this.langMenuClosed.join(" ")} onClick={() => {this.handleScroll0(); this.toggleMenu()}}>
                                        {hiddenMenuFlags}
                                    </div>
                                </li>
                            </ul>
                            <div className={classes.wrapper}>
                                <div className={[classes.mobileMenu, (this.state.mobileMenuOpen ? classes.change : "")].join(' ')} onClick={() => this.toggleMenu()}>
                                    <div className={classes.bar1}></div>
                                    <div className={classes.bar2}></div>
                                    <div className={classes.bar3}></div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                
                <div className={["faith", (this.state.faith ? "" : "hidden")].join(' ')}>
                    <div className="container">
                        <div>Učeník Ježiša Krista Nazaretského - Kristova Cirkev je pravé vierovyznanie každého kresťana</div> 
                    </div>
                    <div className="close" onClick={() => this.closeFaith()}>X</div>
                </div>
            </header>
        );
    }

}

export default Header;