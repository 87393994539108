import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import imgFairytales from "../../assets/images/fairytales_CD.png";

import classes from './Home.module.scss';


class Home extends Component {
    render() {
        const settings = {
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 1500,
          slidesToShow: 1,
          slidesToScroll: 1
        };
    
        return (
          <div className={classes.home}>
            <Slider {...settings}>
              <div className={classes.slide1 + " slide"}>
                    <div className={classes.slideContent + " container"}>
                        <div className={classes.imgBook}></div>
                        <div className={classes.text}>
                            <h1 className={classes.yellow}>Učenie Ježiša Krista Nazaretského</h1>
                            <div className={classes.btnsRow}>
                            </div>
                        </div>
                    </div>
              </div>
              <div className={classes.slide2 + " slide"}>
                    <div className={classes.slideContent + " container"}>
                       <div className={classes.text}>
                           <h3>Peter Fijalka</h3>
                           <h1>Audio nahrávka knihy UJKN</h1>
                           <p>Audio nahrávka knihy obsahuje životný príbeh. Audio nahrávka ku knihe UJKN Učeník Ježiša Krista Nazaretského. Započúvajte sa do nádherného príbehu vyrozprávaného samotným autorom knihy.</p>
                           <div className={classes.btnsRow}>
                               <Link to="/kniha" className={['btnOutline', classes.btnOutlineWhite, classes.btn].join(' ') + " btn"}>Dozvedieť sa viac</Link>
                            </div>
                        </div>
                    </div>
              </div>
              <div className={classes.slide3 + " slide"}>
                    <div className={classes.slideContent + " container"}>
                        <img src={imgFairytales} className={classes.imgFairytales} alt="obrazok Nebeskoslovenské rozprávky"></img>
                        <div className={classes.text}>
                            <h3>Peter Fijalka</h3>
                            <h1>Nebeskoslovenské rozprávky</h1>
                            <p>Rozprávky o veveričke Verke, ježkovi Peťkovi a orlíkovi Jankovi nielen pre deti. Započúvajte sa do nádherných rozprávok vyrozprávanych samotným autorom.</p>
                            <div className={classes.btnsRow}>
                                <Link to="/rozpravky" className={['btnOutline', classes.btnOutlineBlack].join(' ') + " btn"}>Počúvaj audio</Link>
                            </div>
                        </div>
                    </div>
              </div>
              <div className=" slide">
                    <div className={classes.slide4}></div>
                    <div className={classes.slideContent + " container"}>
                        <div className={classes.text}>
                            <h1>Občianske združenie UJKN</h1>
                            <div className={classes.btnsRow}>
                                <Link to="/oz" className={['btnOutline', classes.btnOutlineBlack].join(' ') + " btn"}>Dozvedieť sa viac</Link>
                            </div>
                        </div>
                    </div>
              </div>
            </Slider>
          </div>
        );
      }
}

export default Home;