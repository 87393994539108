import React, { Component } from 'react';

import bookImg from '../../assets/images/book_lighter_shadow.jpg';
import signature from '../../assets/images/signature.jpg';
import logo from '../../assets/images/logo.png';
import flag_sk from '../../assets/images/flag_sk.jpg';
import flag_ru from '../../assets/images/flag_ru.jpg';
import flag_de from '../../assets/images/flag_de.jpg';
import bookDownloadSk from '../../assets/files/UJKN_Fijalka.pdf';
import bookDownloadDe from '../../assets/files/UJKN_Fijalka-de.pdf';
import chapter01Download from '../../assets/files/book-chapters/01_detsky_travnicky_sen.pdf';
import chapter02Download from '../../assets/files/book-chapters/02_vrabelska_euforia.pdf';
import chapter03Download from '../../assets/files/book-chapters/03_trnavske_silenzio.pdf';
import chapter04Download from '../../assets/files/book-chapters/04_zelena_juliska.pdf';
import chapter05Download from '../../assets/files/book-chapters/05_belasa_nostalgia.pdf';
import chapter06Download from '../../assets/files/book-chapters/06_obratenie_saula.pdf';
import chapter07Download from '../../assets/files/book-chapters/07_spoved.pdf';
import chapter08Download from '../../assets/files/book-chapters/08_futbalovy_hilfsarbeiter.pdf';
import chapter09Download from '../../assets/files/book-chapters/09_ave_maria.pdf';
import chapter10Download from '../../assets/files/book-chapters/10_mystika.pdf';
import chapter11Download from '../../assets/files/book-chapters/11_manzelstvo.pdf';
import chapter12Download from '../../assets/files/book-chapters/12_knazstvo.pdf';
import chapter13Download from '../../assets/files/book-chapters/13_posolstvo.pdf';

import BookAudio from '../BookAudio/BookAudio';

import classes from './Book.module.scss';

class Book extends Component {
    state = {
        showDownloadIndividualy: false,
    }
    
    show;

    toggleDisplay() {
        this.setState({showDownloadIndividualy: !this.state.showDownloadIndividualy});
    }
    
    render() {
        this.show = this.state.showDownloadIndividualy ? "" : "displayNone";

        return (
            <section className={classes.book} id="book">
                <div className="container-smaller top">
                    <div className={["row", classes.flexAlign].join(" ")}>
                        <div className="col1">
                            <img src={bookImg} alt="" className={classes.bookSmall}/>
                        </div>
                        <div className={classes.col2}>
                            <h2 className="violet">Učeník Ježiša Krista Nazaretského</h2>
                            <h4 className="violet">Peter Fijalka</h4>
                            <div className={classes.handwritten}>
                                Túto životopisnú knihu venujem všetkým, ktorí sa akýmkoľvek spôsobom pričinili o to, aby som sa stal lepším človekom.
                            </div>
                            <div className="row flex-end">
                                <img src={signature} alt="" className={classes.signature}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.bookIntro}>
                    <div className="container">
                        <div className={classes.textContainer}>
                            <img className={classes.logoBig} src={logo} alt="logo"/>    
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Kruh</span> je symbolom večnosti a nekonečnosti.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Trojuholník</span> je symbolom Božej Trojice.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Dvojkríž</span> je symbolom vesmírnych zákonov, symbol bratstva, 
                                    <br/>symbol spojenia východu so západom
                                    <br/>a symbol spojenia Kristovho utrpenia s naším utrpením.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">U</span> je iniciálka učeníka, má podobu nádoby, skúmavky. 
                                    <br/>Skúmať znamená hľadať pravdu. U je nádobou Ducha Svätého
                                    <br/>a zároveň je aj symbolom vesmíru.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">J</span> je iniciálka mena Ježiš, znamená Emanuel, Boh s nami, Spasiteľ. 
                                    <br/>Písmeno J má podobu kotvy, nádeje a záchrany.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">K</span> znamená Kristus, z gréckeho Christos, 
                                    <br/>Bohom Otcom Pomazaný Prvorodený Syn, Kráľ duchov.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">N</span> je Nazaretský. Je to pečať pravosti.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Kalich s hostiou</span> predstavuje Eucharistiu.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Ryba</span> je symbol prvých kresťanov.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">

                <div className={classes.download}>
                        <div className="w-50">
                            <div className={classes.linkList}>
                                <div className="section">
                                    <h3 className={classes.h3}>Kniha dostupná online tu:</h3>
                                    <div className="row column special-row">
                                        <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/ujkn/docs/ujkn_fijalka" target="_blank" rel="noopener noreferrer">Čítaj online slovensky<img className={classes.flag} src={flag_sk} alt="sk"/> </a>
                                        <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/pm_work/docs/ujkn_ru" target="_blank" rel="noopener noreferrer">Čítaj online rusky<img className={classes.flag} src={flag_ru} alt="ru"/> </a>
                                        <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/ujkn/docs/ujkn_de" target="_blank" rel="noopener noreferrer">Čítaj online nemecky<img className={classes.flag} src={flag_de} alt="de"/> </a>
                                    </div>
                                </div>
                                <div className="section">
                                    <h3 className={classes.h3}>Kniha na stiahnutie (pdf):</h3>
                                    <div className="row column">
                                        <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={bookDownloadSk} download>
                                            Stiahni celú knihu (25 MB)&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                        </a>
                                        <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={bookDownloadDe} download>
                                            Stiahni celú knihu v nemeckom jayzku (25 MB)&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                        </a>
                                        <button className={[classes.btnGeneral, classes.downloadBtn].join(" ")} onClick={() => this.toggleDisplay()}>Stiahni jednotlivé kapitoly (iba SK)</button>
                                    </div>
                                    <div className={[classes.downloadIndividualy, this.show].join(" ")}>
                                        <ul>
                                            <li><a href={chapter01Download} download>1. Detský trávnický sen<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter02Download} download>2. Vrábeľská eufória<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter03Download} download>3. Trnavské silenzio<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter04Download} download>4. Zelená Juliska<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter05Download} download>5. Belasá nostalgia<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter06Download} download>6. Obrátenie Šaula<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter07Download} download>7. Spoveď<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter08Download} download>8. Futbalový Hilfsarbeiter<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter09Download} download>9. Ave Mária<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter10Download} download>10. Mystika<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter11Download} download>11. Manželstvo<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter12Download} download>12. Kňazstvo<i className="fa fa-download"></i></a></li>
                                            <li><a href={chapter13Download} download>POSOLSTVO<i className="fa fa-download"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={["w-50", "section", classes.was].join(" ")}>
                            <h3 className={[classes.h3audio, classes.btnGeneral, classes.audioBtn].join(" ")}>Audio nahrávka knihy</h3>
                            <BookAudio></BookAudio>
                            <p className="small">Audio nahrávku knihy si môžete stiahnuť po jednotlivých kapitolách. Kliknite na kapitolu, ktorú chcete stiahnuť (nahrávka sa začne prehrávať - môžete ju nechať pustenú alebo zastaviť). Následne kliknite na symbol troch bodiek pod sebou (vpravo od ikonky úpravy hlasitosti) v prehrávači a potvrďte stiahnutie.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Book;