import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Testimony from '../Testimonies/Testimony/Testimony';
import classes from './Testimonies.module.scss';


const data = require('./testimonies.json');
data.reverse();
class Testimonies extends Component {

    testimonyScrollToTop = (id) => {
        window.scrollTo(0,0);
    }

    render() {
        const testimoniesList = data.map((testimony) => {
            return (
                <Link to={'/svedectva/' + testimony.id} key={testimony.id} className={classes.testimonyLink}
                    onClick={() => this.testimonyScrollToTop(testimony.id)}>
                    <Testimony 
                        title={testimony.title} 
                        perex={testimony.perex} 
                        id={testimony.id}
                        date={testimony.date}>
                    </Testimony>
                </Link>);
        });


        return (
            <div className="container top">
                <div className={classes.bg}></div>
                <h1 className="h1-main">Svedectvá</h1>
                <div className="divider"></div>
                <h3 className="h3-grey">Inšpiratívne skutočné príbehy</h3>
    
                <div className={classes.containerTestinonies}>
                    {testimoniesList}
                </div>
            </div>
        );
    }
}

export default Testimonies;