import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReactAudioPlayer  from 'react-audio-player';


import classes from './TestimonyDetail.module.scss';
import BackButton from '../../../BackButton/BackButton';

import svedectvo_krizanovicova from '../../../../assets/audio/testimonies/svedectvo_krizanovicova.mp3';

const data = require('../../../Testimonies/testimonies.json');

class TestimonyDetail extends Component {

    render() { 

        const numberOfTestimonies = Object.keys(data).length;
        let id = +this.props.match.params.id;
        let selectedTestimony;

        if(id > numberOfTestimonies) { // ak clovek zada cislo svedectva, ktore neexistuje (pocita sa s tym, ze id idu porade od 1), tak ho presmeruje na 404, inak sa vykona else vetva
            return (
                <Redirect to='/404' />
            );
        } else {
            selectedTestimony = data.find(item => item.id === id);
            
            let testimonyContent = Object.values(selectedTestimony.fullText).map((paragraph) => {
                return <p className={classes.mb20} dangerouslySetInnerHTML={ { __html: paragraph } } key={paragraph.slice(0,30)}></p>;
            });

            // 2 nasledujuce premenne su tu koli vyzve, aby to mohlo byt fialove a orazovy nadpis

            let testimonyContentStyle;
            let headingStyle;

            if(id === 5) {
                testimonyContentStyle = <div className="violet">{testimonyContent}</div>;
                headingStyle = <h2 className={[classes.mb20, "orangered"].join(" ")}>{selectedTestimony.title}</h2>
            }
            else {
                testimonyContentStyle= <div>{testimonyContent}</div>;
                headingStyle = <h2 className={classes.mb20}>{selectedTestimony.title}</h2>
            }

            if(selectedTestimony.audio) {
                var audioTitle = selectedTestimony.audio.split('.')[0];

                if(audioTitle === 'svedectvo_krizanovicova') {
                    var audioPlayer = <ReactAudioPlayer controls={true} autoPlay={false} src={svedectvo_krizanovicova} className={["audioPlayer", classes.audioPlayer].join(" ")}/>
                }
            }
            
            let date = Object.values(selectedTestimony.date);
            let author = selectedTestimony.author ? <p className={classes.author}>Autor: {Object.values(selectedTestimony.author)}</p> : <p></p>;
            
            return (
                <div className={classes.testimonyFull + ' container top'}>
                    <div className={classes.bg}></div>
                    <div className={classes.row + ' row'}>
                        <div className="row column">
                            {headingStyle}
                        </div>
                        <div className={classes.end}>
                            <h2>SVEDECTVO</h2> 
                            <h3>{date}</h3>
                        </div>
                    </div>
                    <div>
                        {audioPlayer}
                    </div>
                    {testimonyContentStyle}
                    {author}
                    <BackButton />
                </div>
            );
        }
    }

}

export default TestimonyDetail;