import React from 'react';

import classes from './Contact.module.scss';

const contact = () => {
    return (
        <section className={classes.contact}>
            <div className={classes.bg}></div>
            <div className="container">
                <h1 className="h1-main top">Kontakt</h1>
                <div className="divider"></div>

                <h3 className="h3-grey">Buďte s nami v kontakte</h3>
                <div className={classes.row + " row"}>
                    <div className={classes.contanctAbout + " w-50"}>
                        <div className={classes.contactInfo}>
                            <h4>Občianske združenie UJKN</h4>
                            <p>Bratislava</p>
                            <p>Slovensko</p>
                            <a href="mailto:fialky@centrum.sk" className={classes.mailto}>fialky@centrum.sk</a>
                            <p>0907 780 043</p>
                            <h4 className={classes.bank}>Bankové spojenie:</h4>
                            <p>SK23 8330 0000 0021 0166 3662</p>
                            <p>IČO: 52 439 721</p>
                        </div>
                    </div>
                    <div className="w-50">
                        <h4>Podeľte sa s nami o Vaše príbehy</h4>
                        <p>Ak sa chcete podeliť o svoj osobný príbeh, svedectvo, napíšte nám mail na <a href="mailto:fialky@centrum.sk" className={classes.link}>fialky@centrum.sk</a>, prípadne nám pošlite akýkoľvek dokument s editovateľným textom. Vybrané príspevky budú uverejnené na tejto stránke.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default contact;