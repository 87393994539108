import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import flag_sk from '../../assets/images/flag_sk.jpg';
import flag_ru from '../../assets/images/flag_ru.jpg';
import flag_de from '../../assets/images/flag_de.jpg';
import signature from '../../assets/images/signature.jpg';
import bookImg from '../../assets/images/book_lighter_shadow.jpg';
import bookDownloadSk from '../../assets/files/UJKN_Fijalka.pdf';
import bookDownloadDe from '../../assets/files/UJKN_Fijalka-de.pdf';

import classes from '../Book/Book.module.scss';
import classesDe from '../DE/De.module.scss';

const Ru = () => {
    return (
        <div>
            <section className={classesDe.section}>
                <div className="container-smaller top">
                    <div className="actualities bold">
                        НОВОСТИ: Вы можете прочитать новую статью <NavLink to="/ru/oбращение-к-словацкому-народу" className="orangered underlined">Обращение к словацкому народу здесь</NavLink>.
                        <br></br>
                        Свидетельство Катарины Крижановичовой можно прочитать <NavLink to="/ru/свидетельство-крижановичова" className="orangered underlined">здесь</NavLink>.
                    </div>
                    <div className={["row", classes.flexAlign].join(" ")}>
                        <div className="col1">
                            <img src={bookImg} alt="" className={classes.bookSmall}/>
                        </div>
                        <div className={classes.col2}>
                            <h2 className="violet">Ученик Иисуса Христа Назарея</h2>
                            <h4 className="violet">Петер Фийалка</h4>
                            <div className={classes.handwritten}>
                                Зту биографическую книгу я посвящаю всем людям, которые помогли мне, каждый по своему, стать более лучшим человеком.
                            </div>
                            <div className="row flex-end">
                                <img src={signature} alt="" className={classes.signature}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classesDe.section}>
                <div className={classes.bookIntro}>
                    <div className="container">
                        <div className={classes.textContainer}>
                            <img className={classes.logoBig} src={logo} alt="logo"/>    
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Круг</span> – символ вечности и бесконечности.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Треугольник</span> – символ Божьей Троицы.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Прямой шестиконечный крест</span> – символ космических законов,
                                    <br/>символ братства,
                                    <br/> символ соединения Востока с Западом
                                    <br/>и символ соединения мук Христа с нашим страданием.  
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">U</span> – в словацком языке начальная буква слова «učeník – ученик». 
                                    <br/>Она имеет  подобие сосуда, пробирки. Пробирка является символом исследования. 
                                    <br/>Исследовать – значит искать, искать Правду.
                                    <br/><span className="bold">U</span> – это сосуд Святого Духа и одновременно символ Вселенной.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">J</span> – начальная буква имени Иисус (по-слoвацки Ježiš), означает Эммануил, «Бог с нами», Спаситель.
                                    <br/>Буква <span className="bold">J</span> напоминает якорь надежды и спасения.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">К</span> – в словацком языке начальная буква имени Христоc (Kristus), 
                                    <br/>Богом-Отцом Помазанный, Перворождённый Сын, Царь духов.
                                </p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">N</span> – Назарей. Это печать подлинности.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Чаша с облаткой</span> представляет собой Евхаристию.</p>
                            </div>
                            <div className={classes.pWrapper}>
                                <p><span className="bold">Рыба</span> – символ первых христиан.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classesDe.section}>
                <div className="container">
                    <div className={["row", classesDe.row].join(" ")}>
                        <div className="w-50">
                            <div className="section">
                                <h3 className={classes.h3}>Kнига для чтения онлайн:</h3>
                                <div className="row column special-row">
                                    <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/pm_work/docs/ujkn_ru" target="_blank" rel="noopener noreferrer">Читать онлайн на русском<img className={classes.flag} src={flag_ru} alt="ru"/></a>
                                    <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/ujkn/docs/ujkn_fijalka" target="_blank" rel="noopener noreferrer">Читать онлайн на словацком<img className={classes.flag} src={flag_sk} alt="sk"/></a>
                                    <a className={[classes.btnGeneral, classes.readBtn].join(" ")} href="https://issuu.com/ujkn/docs/ujkn_de" target="_blank" rel="noopener noreferrer">Читать онлайн на немецком<img className={classes.flag} src={flag_de} alt="de"/></a>
                                </div>
                            </div>
                        </div>

                        <div className="w-50">
                            <div className="section">
                                <h3 className={classes.h3}>Скачать книгу (pdf):</h3>
                                <div className="row wrap">
                                    <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={bookDownloadSk} download>
                                    Скачать книгу на словацком языке (25 Mб)&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                    </a>
                                    <a className={[classes.btnGeneral, classes.downloadBtn].join(" ")} href={bookDownloadDe} download>
                                    Скачать книгу на немецком языке (25 Mб)&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={classesDe.section}>
                <div className="container">
                    <h3 className={classesDe.h3}>контакт</h3>
                    <p>Братислава, Словацкая Республика</p>

                    <a href="mailto:fialky@centrum.sk" className={classesDe.mailto}>fialky@centrum.sk</a>

                    <h4>Банковский счет:</h4>
                    <p>SK23 8330 0000 0021 0166 3662</p>
                </div>
            </section>
        </div>
    );
}

export default Ru;