import React, { Component } from 'react';

import ReactAudioPlayer  from 'react-audio-player';

import classes from './FairyTales.module.scss';

import chapter01 from '../../assets/audio/fairytales/00.mp3';
import chapter02 from '../../assets/audio/fairytales/01.mp3';
import chapter03 from '../../assets/audio/fairytales/02.mp3';
import chapter04 from '../../assets/audio/fairytales/03.mp3';
import chapter05 from '../../assets/audio/fairytales/04.mp3';
import chapter06 from '../../assets/audio/fairytales/05.mp3';
import chapter07 from '../../assets/audio/fairytales/06.mp3';
import chapter08 from '../../assets/audio/fairytales/07.mp3';
import chapter09 from '../../assets/audio/fairytales/08.mp3';
import chapter10 from '../../assets/audio/fairytales/09.mp3';
import chapter11 from '../../assets/audio/fairytales/10.mp3';
import chapter12 from '../../assets/audio/fairytales/11.mp3';
import chapter13 from '../../assets/audio/fairytales/12.mp3';

import fairytalesImg from '../../assets/images/fairytales_CD.png';

class FairyTales extends Component {
    state = {
        activeChapter: 0,
        chapterSet: [
            { id: 1, name: 'Kapitola 01', src: chapter01 }, 
            { id: 2, name: 'Kapitola 02', src: chapter02 }, 
            { id: 3, name: 'Kapitola 03', src: chapter03 }, 
            { id: 4, name: 'Kapitola 04', src: chapter04 }, 
            { id: 5, name: 'Kapitola 05', src: chapter05 }, 
            { id: 6, name: 'Kapitola 06', src: chapter06 }, 
            { id: 7, name: 'Kapitola 07', src: chapter07 }, 
            { id: 8, name: 'Kapitola 08', src: chapter08 }, 
            { id: 9, name: 'Kapitola 09', src: chapter09 }, 
            { id: 10, name: 'Kapitola 10', src: chapter10 }, 
            { id: 11, name: 'Kapitola 11', src: chapter11 }, 
            { id: 12, name: 'Kapitola 12', src: chapter12 }, 
            { id: 13, name: 'Kapitola 13', src: chapter13 }
        ],
        autoPlay: false
    }
    
    newChapter(index) {
        this.setState({activeChapter: index, autoPlay: true});
    }
    
    render() {
        const { activeChapter, chapterSet, autoPlay } = this.state;

        const audioList = chapterSet.map((chapter, index) => {
            return (
                <li key={chapter.id} onClick={() => this.newChapter(index)}>
                    {chapter.name}
                </li>
            );
        });


        return (
            <div className="container top">
                <div className={classes.bg}></div>
                <h1 className="h1-main">Nebeskoslovenské rozprávky</h1>
                <div className="divider"></div>

                <div className={["row", classes.row].join(" ")}>
                    <div className="w-50">
                        <div className="section">
                            <img src={fairytalesImg} className={classes.img} alt="obrázok Nebeskoslovenské rozprávky"/>
                        </div>
                    </div>

                    <div className="w-50">
                        <div className="section">
                            <div>
                                <p className="current">Práve sa prehráva: <span className="activeAudio">{this.state.chapterSet[this.state.activeChapter].name}</span></p>
                                <ReactAudioPlayer
                                    controls={true}
                                    autoPlay={autoPlay}
                                    src={chapterSet[activeChapter].src}
                                    className="audioPlayer"
                                />
                                <ul className="audio-component">
                                    {audioList}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default FairyTales;