import React, { Component } from 'react';

import classes from './BackButton.module.scss';

class BackButton extends Component {

    goBack = () => {
        window.history.back();
        window.scrollTo(0,0);
    }

    render() {        
        return (
            <div className={classes.backButton} onClick={this.goBack}>
                <p><span className={classes.rotate180}>➜</span></p>
            </div>
        );
    }
}
export default BackButton;