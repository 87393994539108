import React, { Component } from 'react';

import classes from './Footer.module.scss';

class Footer extends Component {

    styleClasses = [classes.footer];

    render() {        
        if(this.props.home) {
            this.styleClasses.push(classes.footerFixed);
        }
        else {
           this.styleClasses = [classes.footer];
        }
        
        return (
            <div className={this.styleClasses.join(' ')}>
                <div className="container">
                    <div className={classes.footerContent}>
                        <div>Copyright 	&copy; 2020 - Peter Fijalka</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;