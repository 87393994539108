import React, { useEffect, useState } from "react";
import classes from './Game.module.scss';
import questions from './Q&N.json';

const Game = () => {
  const [answerIsVisible, setAnswerIsVisible] = useState(false); 
  const [playedQuestions, setPlayedQuestions] = useState([]);

  const randomQ = () => {
    let qNumber = Math.floor(Math.random() * 287);
    
    if (playedQuestions.length < 18) {
      while(playedQuestions.includes(qNumber)) {
        qNumber = Math.floor(Math.random() * 287);
      }
    } else {
      setPlayedQuestions([]);
    }

    console.log('includes', playedQuestions.includes(qNumber));
    setPlayedQuestions(prevQs => [...prevQs, qNumber]);
    return qNumber;
  } 

  const [actualQuestion, setActualQuestion] = useState(randomQ);

  useEffect(() => {
      randomQ();
    }, []);

  const goToNewQuestion = () => {
    setAnswerIsVisible(false);
    
  
    setActualQuestion(randomQ());
    console.log('played questions ', playedQuestions);
    console.log('actual question ', actualQuestion);
    // setActualQuestion(prevQuestion => prevQuestion === questions.length - 1 ? 0 : prevQuestion + 1);
  }

  const showTheAnswer = () => {
    setAnswerIsVisible(true);
  };

  const answerClasses = answerIsVisible ? classes.fadeIn : "";

  return (
    <div className="container top">
      <div className={classes.bg}></div>
      <h1 className="h1-main">Už je koniec nevedomosti</h1>
      <p className={classes.intro}>Vedomostná, poučná a zábavná hra o Slovensku zostavená s dôrazom na prácu so slovom. Rozvíja dvôvtip, intuíciu, koncentráciu a logické myslenie. Účinná proti začínajúcim Alzheimerom a stratám pamäti.</p>
      <p className={classes.intro2}>Veselú zábavu Slováci!</p>
      <div className={classes.wrapper}>
        <div className={classes.qaContainer}> 
          <div className={classes.question}>
            <div>
              <p className={classes.question__part1}>{questions[actualQuestion].q1}</p>
              <p className={classes.bold}>{questions[actualQuestion].q2}</p>
            </div>
          </div>
          <div className={`${classes.answer} ${classes.bold}`} onClick={showTheAnswer}>
            {!answerIsVisible && <p>Zobraziť odpoveď</p>}
            {answerIsVisible && <p className={answerClasses}>{questions[actualQuestion].a}</p>}
          </div>
        </div>
        <div className={classes["btn-container"]}>
          <button onClick={showTheAnswer} className={classes.btn}>Zobraziť odpoveď</button>
          <button onClick={goToNewQuestion} className={classes.btn}>Ďalšia otázka</button>
        </div>
      </div>
    </div>
  )
};

export default Game;