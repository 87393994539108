import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import './App.scss';
import Header from './components/Header/Header';
import Testimonies from './components/Testimonies/Testimonies';
import Home from './components/Home/Home';
import Book from './components/Book/Book';
import FairyTales from './components/FairyTales/FairyTales';
import Footer from './components/Footer/Footer';
import TestimonyDetail from './components/Testimonies/Testimony/TestimonyDetail/TestimonyDetail';
import Oz from './components/Oz/Oz';
import Contact from './components/Contact/Contact';
import De from './components/DE/De';
import DeCall from './components/DE/DeCall';
import Ru from './components/RU/Ru';
import RuCall from './components/RU/RuCall';
import NotFound from './components/NotFound/NotFound';
import Krizanovicova from './components/DE/krizanovicova';
import RuKrizanovicova from './components/RU/RuKrizanovicova';
import Game from './components/Game/Game';

class App extends Component {
  urlHome;

  render() {
    this.urlHome = this.props.location.pathname === '/' ? true : false;

    return (
      <div className="App">
        <Header></Header>
          <div className="main"> 
            <Switch>
              <Route path="/" exact component={Home}/>
              <Route path="/kniha" exact component={Book}/>
              <Route path="/rozpravky" exact component={FairyTales}/>
              <Route path="/hra" exact component={Game}/>
              <Route path="/svedectva" exact component={Testimonies}/>
              <Route path="/svedectva/:id" exact component={TestimonyDetail}/>
              <Route path="/oz" exact component={Oz}/>
              <Route path="/kontakt" exact component={Contact}/>
              <Route path="/de/aufruf-auf-das-slowakische-volk" exact component={DeCall}/>
              <Route path="/de/zeugnis-krizanovicova" exact component={Krizanovicova}/>
              <Route path="/de" exact component={De}/>
              <Route path="/ru/oбращение-к-словацкому-народу" exact component={RuCall}/>
              <Route path="/ru/свидетельство-крижановичова" exact component={RuKrizanovicova}/>
              <Route path="/ru" exact component={Ru}/>
              <Route path="/404" exact component={NotFound}/>
              <Route component={NotFound}/>
            </Switch>
          </div>
        <Footer home={this.urlHome}></Footer>
      </div>
    );
  }
}

export default withRouter(App);
